import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from '@remix-run/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'

import SomethingWentWrong from '~/components/shared/SomethingWentWrong'

import '~/styles/index.scss'

export function Layout({ children }) {
  dayjs.extend(relativeTime)

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

function App() {
  const [user, setUser] = useState({})

  return <Outlet context={{ user, setUser }} />
}

export default withSentry(App)

export function HydrateFallback() {
  return (
    <div className="fallback">
      <span className="loader"></span>
    </div>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  return <SomethingWentWrong />
}
