import { Link } from '@remix-run/react'

function SomethingWentWrong() {
  return (
    <div className="error-boundary">
      <img
        className="error-boundary__image"
        alt="Something went wrong illustration"
        src="/assets/images/hitex-character.png"
      />
      <div className="error-boundary__content">
        <h2 className="error-boundary__title">
          Sorry! Something has gone terribly wrong
        </h2>
        <p className="error-boundary__description">
          Please try again later. But if the problem persists, contact support.
        </p>
      </div>
      <Link to=".." reloadDocument>
        <button className="error-boundary__button">Go back</button>
      </Link>
    </div>
  )
}

export default SomethingWentWrong
